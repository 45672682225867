import { Action, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Sort, TimeSort } from "../../components/Features/Catalog/catalogUtils";
import { Brand, Generation, Model } from "./types";
import { AppState } from "@app/store/store";
import { HYDRATE } from "next-redux-wrapper";
import { City } from "@shared/lib/interfaces/city.interface";

export interface FiltersState {
    brand?: Brand;
    model?: Model | null;
    generation?: Generation | null;
    yearFrom?: number;
    yearTo?: number;
    price: string;
    mileageFrom?: number;
    mileageTo?: number;
    transmission?: string;
    drive?: string;
    body?: string;
    color: string;
    city: City[];
    engineType?: string;
    priceFrom?: number;
    priceTo?: number;
    horsePowerFrom?: number;
    horsePowerTo?: number;
    wheel?: string;
    ownersQntFrom?: number;
    ownersQntTo?: number;
    sourceId?: number;
    sellerType?: string;
    createdAt?: string;
    engineVolumeFrom?: number;
    engineVolumeTo?: number;
    mainScreenTimeSort?: TimeSort;
    mainScreenAllParamsSort?: Sort;
    catalogTimeSort?: TimeSort;
    catalogAllParamsSort?: Sort;
    previewQnt: number | null;
    cityCenterCoords?: number[];
}

const initialState = {
    previewQnt: null,
    catalogTimeSort: TimeSort.AllTime,
    price: "",
    color: "",
    city: [],
    mainScreenTimeSort: TimeSort.Week
} as FiltersState;

function isHydrateAction(action: Action): action is PayloadAction<AppState> {
    return action.type === HYDRATE;
}

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setBrand(state, action) {
            const newBrand = action.payload;
            if (!newBrand || !state.brand || state.brand.id !== newBrand.id) {
                state.model = null;
                state.generation = null;
            }
            state.brand = newBrand;
        },
        setModel(state, action) {
            const newModel = action.payload;
            if (!newModel || !state.model || state.model.id !== newModel.id) {
                state.generation = null;
            }
            state.model = newModel;
        },
        setGeneration: (state, action) => {
            state.generation = action.payload;
        },
        setYearFrom(state, action) {
            state.yearFrom = action.payload;
        },
        setYearTo(state, action) {
            state.yearTo = action.payload;
        },
        setBody: (state, action) => {
            state.body = action.payload;
        },
        setTransmission: (state, action) => {
            state.transmission = action.payload;
        },
        setEngineType: (state, action) => {
            state.engineType = action.payload;
        },
        setDrive: (state, action) => {
            state.drive = action.payload;
        },
        setPriceFrom: (state, action) => {
            state.priceFrom = action.payload;
        },
        setPriceTo: (state, action) => {
            state.priceTo = action.payload;
        },
        setMileageFrom: (state, action) => {
            state.mileageFrom = action.payload;
        },
        setMileageTo: (state, action) => {
            state.mileageTo = action.payload;
        },
        setHorsePowerFrom: (state, action) => {
            state.horsePowerFrom = action.payload;
        },
        setHorsePowerTo: (state, action) => {
            state.horsePowerTo = action.payload;
        },
        setWheel: (state, action) => {
            state.wheel = action.payload;
        },
        setOwnersQntFrom: (state, action) => {
            state.ownersQntFrom = action.payload;
        },
        setOwnersQntTo: (state, action) => {
            state.ownersQntTo = action.payload;
        },
        setCreatedAt: (state, action) => {
            state.createdAt = action.payload;
        },
        setCities: (state, action: PayloadAction<City[] | undefined>) => {
            const payload = action.payload;
            if (!payload || payload.length === 0 || (payload.length === 1 && !payload[0].name)) {
                state.city = [];
                return;
            }
            state.city = payload;
        },
        setCityCenterCoords: (state, action) => {
            state.cityCenterCoords = action.payload;
        },
        setSource(state, action) {
            state.sourceId = action.payload;
        },

        setSeller(state, action) {
            state.sellerType = action.payload;
        },

        setEngineVolumeFrom(state, action) {
            state.engineVolumeFrom = action.payload;
        },

        setEngineVolumeTo(state, action) {
            state.engineVolumeTo = action.payload;
        },

        optimiseFilters: (state) => {
            if (state.priceFrom && state.priceTo) {
                if (state.priceFrom > state.priceTo) {
                    [state.priceFrom, state.priceTo] = [state.priceTo, state.priceFrom];
                }
            }

            if (state.yearFrom && state.yearTo) {
                if (state.yearFrom > state.yearTo) {
                    [state.yearFrom, state.yearTo] = [state.yearTo, state.yearFrom];
                }
            }

            if (state.mileageFrom && state.mileageTo) {
                if (state.mileageFrom > state.mileageTo) {
                    [state.mileageFrom, state.mileageTo] = [state.mileageTo, state.mileageFrom];
                }
            }

            if (state.horsePowerFrom && state.horsePowerTo) {
                if (state.horsePowerFrom > state.horsePowerTo) {
                    [state.horsePowerFrom, state.horsePowerTo] = [
                        state.horsePowerTo,
                        state.horsePowerFrom
                    ];
                }
            }

            if (state.ownersQntFrom && state.ownersQntTo) {
                if (state.ownersQntFrom > state.ownersQntTo) {
                    [state.ownersQntFrom, state.ownersQntTo] = [
                        state.ownersQntTo,
                        state.ownersQntFrom
                    ];
                }
            }

            if (state.engineVolumeFrom && state.engineVolumeTo) {
                if (state.engineVolumeFrom > state.engineVolumeTo) {
                    [state.engineVolumeFrom, state.engineVolumeTo] = [
                        state.engineVolumeTo,
                        state.engineVolumeFrom
                    ];
                }
            }
        },
        clearFilters: (state) => {
            state.brand = undefined;
            state.model = undefined;
            state.yearFrom = undefined;
            state.yearTo = undefined;
            state.priceFrom = undefined;
            state.priceTo = undefined;
            state.price = "";
            state.transmission = "";
            state.drive = "";
            state.body = "";
            state.color = "";
            state.city = [];
            state.cityCenterCoords = undefined;
            state.engineType = "";
            state.generation = undefined;
            state.horsePowerFrom = undefined;
            state.horsePowerTo = undefined;
            state.wheel = undefined;
            state.ownersQntFrom = undefined;
            state.ownersQntTo = undefined;
            state.sellerType = undefined;
            state.sourceId = undefined;
            state.engineVolumeFrom = undefined;
            state.engineVolumeTo = undefined;
            state.mileageFrom = undefined;
            state.mileageTo = undefined;
            state.catalogTimeSort = TimeSort.AllTime;
        },

        setMainScreenTimeSort: (state, action) => {
            state.mainScreenTimeSort = action.payload;
        },
        setMainScrenParamsSort: (state, action) => {
            state.mainScreenAllParamsSort = action.payload;
        },
        setCatalogTimeSort: (state, action) => {
            state.catalogTimeSort = action.payload;
        },
        setCatalogParamsSort: (state, action) => {
            state.catalogAllParamsSort = action.payload;
        }
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            return (state = {
                ...state,
                ...action.payload.filters
            });
        }
    }
});

export const saveCity = createAsyncThunk(
    "filters/saveCity",
    async (city: City | undefined, thunkAPI) => {
        try {
            if (city) {
                localStorage.setItem("city", JSON.stringify(city));
                thunkAPI.dispatch(setCities([city]));
            } else {
                localStorage.removeItem("city");
                thunkAPI.dispatch(setCities([]));
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                return thunkAPI.rejectWithValue(e.message);
            } else {
                return thunkAPI.rejectWithValue("Произошла неизвестная ошибка");
            }
        }
    }
);

export const {
    setBrand,
    setModel,
    setYearFrom,
    setYearTo,
    setBody,
    setTransmission,
    setEngineType,
    setDrive,
    setGeneration,
    setPriceFrom,
    setPriceTo,
    setMileageFrom,
    setMileageTo,
    setHorsePowerFrom,
    setHorsePowerTo,
    setWheel,
    setOwnersQntFrom,
    setOwnersQntTo,
    clearFilters,
    setCities,
    setSource,
    setSeller,
    setCreatedAt,
    optimiseFilters,
    setEngineVolumeFrom,
    setEngineVolumeTo,
    setMainScreenTimeSort,
    setMainScrenParamsSort,
    setCatalogParamsSort,
    setCatalogTimeSort,
    setCityCenterCoords
} = filtersSlice.actions;

export default filtersSlice;