export const REQUEST_BODIES = {
    BRAND_OPTIONS_SORT: {
        sort: [
            {
                name: {
                    order: "asc"
                }
            }
        ]
    },
    CREATE_MODELS_SEARCH_BODY: (brandUuid?: string) => {
        if (!brandUuid) {
            return {};
        }
        return {
            size: 500,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                brand_uuid: brandUuid
                            }
                        }
                    ]
                }
            },
            sort: [
                {
                    name: { order: "asc" }
                }
            ]
        };
    },
    CREATE_CITY_SEARCH_BODY: (cityName: string) => ({
        _source: ["name", "alias"],
        query: {
            bool: {
                must: [
                    {
                        multi_match: {
                            query: cityName,
                            type: "phrase_prefix",
                            fields: ["name", "alias"]
                        }
                    }
                ]
            }
        }
    }),
    CREATE_CITY_SEARCH_BY_NAME_BODY: (cityAlias: string) => ({
        _source: ["name", "alias"],
        query: {
            bool: {
                must: [
                    {
                        match_phrase: {
                            alias: cityAlias
                        }
                    }
                ]
            }
        }
    }),
    RESERVE_CREATE_CITY_SEARCH_BODY: (cityName: string) => ({
        _source: ["city"],
        query: {
            bool: {
                must: [
                    {
                        match_phrase_prefix: {
                            city: cityName
                        }
                    }
                ]
            }
        }
    }),
    RESERVE_CREATE_CITY_SEARCH_BY_NAME_BODY: (cityName: string) => ({
        _source: ["name"],
        query: {
            bool: {
                must: [
                    {
                        match_phrase: {
                            name: cityName
                        }
                    }
                ]
            }
        }
    }),
    CREATE_GENERATIONS_SEARCH_BODY: (modelUuid: string) => ({
        query: {
            bool: {
                must: [
                    {
                        match_phrase: {
                            model_uuid: modelUuid
                        }
                    }
                ]
            }
        },
        sort: {
            generation: "asc"
        }
    })
};