import { usePathname } from "next/navigation";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { wrapper } from "@app/store/store";
import { AuthCheck } from "@components/Features/Auth/AuthCheck";
import "react-toastify/dist/ReactToastify.css";
import "@app/styles/globals.scss";

function App({ Component, ...rest }: AppProps) {
    const pathname = usePathname();
    const { store, props } = wrapper.useWrappedStore(rest);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"
                />
            </Head>
            <Provider store={store}>
                <AuthCheck>
                    <Component {...props.pageProps} />
                </AuthCheck>
            </Provider>
        </>
    );
}

export default App;
