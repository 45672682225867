import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./query";
import { API_ENDPOINTS } from "@shared/api/endpoints";
import { REQUEST_BODIES } from "@shared/api/requestBodies";
import { HYDRATE } from "next-redux-wrapper";

const ELASTIC_API = process.env.NEXT_PUBLIC_ELASTIC;
const API = process.env.NEXT_PUBLIC_API_URL;

type QueryParams = {
    brandUuid?: string;
    modelUuid?: string;
    generationUuid?: string;
};

export const generateParamsFilters = (params: QueryParams): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
    });

    return searchParams.toString() ? `?${searchParams.toString()}` : "";
};

export const filtersOptionsApi = createApi({
    reducerPath: "filtersOptionsApi",
    tagTypes: [
        "BRANDS_OPTIONS",
        "MODELS_OPTIONS",
        "CITY_OPTIONS",
        "BODY_TYPES_OPTIONS",
        "TRANSMISSION_OPTIONS",
        "ENGINE_OPTIONS",
        "DRIVE_TYPES_OPTIONS",
        "GENERATION_OPTIONS",
        "DISPLACEMENTS_OPTIONS",
        "WHEELS_OPTIONS",
        "SELLER_OPTIONS"
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${ELASTIC_API}`,
        headers: { "Content-Type": "application/json" }
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath];
        }
    },
    endpoints: (builder) => ({
        getBrandsOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.BRANDS}`,
                        method: "POST",
                        body: JSON.stringify(REQUEST_BODIES.BRAND_OPTIONS_SORT)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data?.hits?.hits;
                const options = data?.map((item: any) => {
                    return {
                        id: item._source.id,
                        name: item._source.name,
                        alias: item._source.alias
                    };
                });
                return data ? { data: options } : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["BRANDS_OPTIONS"]
        }),
        getModelsOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const requestBody = REQUEST_BODIES.CREATE_MODELS_SEARCH_BODY(args.brandUuid);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.MODEL_SEARCH}`,
                        method: "POST",
                        body: JSON.stringify(requestBody)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data?.hits?.hits;
                const options = data?.map((item: any) => {
                    return {
                        id: item._source.id,
                        name: item._source.name,
                        alias: item._source.alias
                    };
                });
                return data ? { data: options } : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["MODELS_OPTIONS"]
        }),

        getCityOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const requestBody = args.exact
                    ? REQUEST_BODIES.CREATE_CITY_SEARCH_BY_NAME_BODY(args.cityName)
                    : REQUEST_BODIES.CREATE_CITY_SEARCH_BODY(args.cityName);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.CITY_SEARCH}`,
                        method: "POST",
                        body: JSON.stringify(requestBody)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data?.hits?.hits;
                const options = data?.map((item: any) => {
                    return {
                        name: item._source.name,
                        alias: item._source.alias
                    };
                });
                return data ? { data: options } : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["CITY_OPTIONS"]
        }),
        getReserveCityOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const requestBody = args.exact
                    ? REQUEST_BODIES.RESERVE_CREATE_CITY_SEARCH_BY_NAME_BODY(args.cityName)
                    : REQUEST_BODIES.RESERVE_CREATE_CITY_SEARCH_BODY(args.cityName);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.CITY_SEARCH}`,
                        method: "POST",
                        body: JSON.stringify(requestBody)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data?.hits?.hits;
                const options = data?.map((item: any) => {
                    return {
                        name: item._source.name
                    };
                });
                return data ? { data: options } : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["CITY_OPTIONS"]
        }),
        getBodyTypesOptions: builder.query<any, QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                let queryString = "";
                if (args) {
                    queryString = generateParamsFilters(args);
                }

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.BODY_TYPES(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                const options = result.data.data.options.map((el: any) => el.name);
                return options ? { data: options } : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["BODY_TYPES_OPTIONS"]
        }),

        getTransmissionOptions: builder.query<any, QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                const queryString = generateParamsFilters(args);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.TRANSMISSION_TYPES(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                const options = result.data?.data?.options?.map((el: any) => el.name) ?? [];
                return options.length > 0
                    ? { data: options }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["TRANSMISSION_OPTIONS"]
        }),

        getEngineTypesOptions: builder.query<any, QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                const queryString = generateParamsFilters(args);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.ENGINE_TYPES(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                const options = result.data?.data?.options?.map((el: any) => el.name) ?? [];
                return options.length > 0
                    ? { data: options }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["ENGINE_OPTIONS"]
        }),

        getDriveTypesOptions: builder.query<any, QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                const queryString = generateParamsFilters(args);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.DRIVE_TYPES(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                const options = result.data?.data?.options?.map((el: any) => el.name) ?? [];
                return options.length > 0
                    ? { data: options }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["DRIVE_TYPES_OPTIONS"]
        }),

        getGenerationsOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const requestBody = REQUEST_BODIES.CREATE_GENERATIONS_SEARCH_BODY(args.model_uuid);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.GENERATIONS_SEARCH}`,
                        method: "POST",
                        body: JSON.stringify(requestBody)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data?.hits?.hits.map((item: any) => ({
                    id: item._source.id,
                    name: item._source.name,
                    generation: item._source.generation,
                    restyleNumber: item._source.restyle_number,
                    startedAt: item._source.started_at,
                    endedAt: item._source.ended_at
                }));

                return { data: data || [] };
            },
            providesTags: ["GENERATION_OPTIONS"]
        }),
        getDisplacementsOptions: builder.query<string[], QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                const queryString = generateParamsFilters(args);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.DISPLACEMENTS(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                if (result.error) {
                    return { error: result.error as FetchBaseQueryError };
                }

                let options = result.data?.data?.options ?? [];
                options.sort((a: { name: number }, b: { name: number }) => a.name - b.name);
                const optionsRounded = options.map((el: { name: number }) => el.name.toFixed(1));
                return { data: optionsRounded };
            },
            providesTags: ["DISPLACEMENTS_OPTIONS"]
        }),
        getWheelsOptions: builder.query<any, QueryParams>({
            queryFn: async (args, api, extraOptions) => {
                const queryString = generateParamsFilters(args);

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}${API_ENDPOINTS.WHEEL_TYPES(queryString)}`,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                const options = result.data?.data?.options?.map((el: any) => el.name) ?? [];
                return options.length > 0
                    ? { data: options }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["WHEELS_OPTIONS"]
        }),
        getSellerTypesOptions: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const filters = args.brand && args.model ? {} : {};

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${ELASTIC_API}${API_ENDPOINTS.SELLER_TYPES_SEARCH}`,
                        method: "POST",
                        body: JSON.stringify(filters)
                    },
                    api,
                    extraOptions
                );

                const data = result?.data.data?.hits?.hits;
                return data
                    ? {
                          data: Array.from(
                              new Set(data.map((item: any) => item._source.seller_type))
                          )
                      }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["SELLER_OPTIONS"]
        })
    })
});

export const {
    useGetBrandsOptionsQuery,
    useGetModelsOptionsQuery,
    useGetCityOptionsQuery,
    useGetReserveCityOptionsQuery,
    useGetBodyTypesOptionsQuery,
    useGetTransmissionOptionsQuery,
    useGetEngineTypesOptionsQuery,
    useGetDriveTypesOptionsQuery,
    useGetGenerationsOptionsQuery,
    useGetDisplacementsOptionsQuery,
    useGetWheelsOptionsQuery,
    useGetSellerTypesOptionsQuery,
    endpoints: {
        getBrandsOptions,
        getModelsOptions,
        getCityOptions,
        getReserveCityOptions,
        getBodyTypesOptions,
        getTransmissionOptions,
        getEngineTypesOptions,
        getDriveTypesOptions,
        getGenerationsOptions,
        getDisplacementsOptions,
        getWheelsOptions,
        getSellerTypesOptions
    }
} = filtersOptionsApi;